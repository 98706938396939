import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import _ from 'lodash';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import Content from '../components/Content';

const Container = styled.div`
  text-align: center;
`;

const GalleryEntry = styled.div`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
`;

const Gallery = styled.div``;
const Title = styled.div`
  flex: 0 1 70%;
  margin-left: 10px;
`;

const StyledLink = styled(Link)``;

const GalleryPage = () => {
  const data = useStaticQuery(
    graphql`
      query Gallery {
        allFile(
          filter: { sourceInstanceName: { eq: "gallery" } }
          sort: { fields: [relativeDirectory, name], order: ASC }
        ) {
          group(field: relativeDirectory) {
            nodes {
              id
              name
              relativePath
              relativeDirectory
              publicURL
            }
          }
        }
      }
    `
  );

  const {
    allFile: { group },
  } = data;

  return (
    <Layout>
      <Helmet>
        <title>Legacy of Steel</title>
      </Helmet>
      <Content>
        <Container>
          <h1>Gallery</h1>
          <Gallery>
            {_.map(group, g => {
              const node = g.nodes[0];
              let slug = node.relativeDirectory;
              slug = slug.replace('/images', '');
              slug = slug.replace(/\//g, '-');

              return (
                <GalleryEntry key={node.id}>
                  <Title>
                    <StyledLink to={`gallery/${slug}`}>{slug}</StyledLink>
                  </Title>
                </GalleryEntry>
              );
            })}
          </Gallery>
        </Container>
      </Content>
    </Layout>
  );
};
export default GalleryPage;
